import VirtualEdu1 from 'assets/projects/virtualEdu/calendar_full.png';
import VirtualEdu2 from 'assets/projects/virtualEdu/schedule_create.png';
import EventSystem1 from 'assets/projects/officerEvent/event_comp.png';
import EventSystem2 from 'assets/projects/officerEvent/file_page.png';
import EventSystem3 from 'assets/projects/officerEvent/monthly_calendar.png';
import EventSystem4 from 'assets/projects/officerEvent/editor_john_donne.png';
import DevzAI1 from 'assets/projects/devzAI/biz_landing_page.png';
import DevzAI2 from 'assets/projects/devzAI/prelaunch_page.png';
import TmTracker1 from 'assets/projects/tmTracker/tm-tracker-api-best-picks-data.png';
import TmTracker2 from 'assets/projects/tmTracker/api-snippet.png';
import Comments1 from 'assets/projects/comments/comment_tree_structure.png';
import Comments2 from 'assets/projects/comments/spring-logo.svg';
import Comments3 from 'assets/projects/comments/mysql-logo.jpeg';
// import loadImage from 'utils/image';
// const filePathPrefix = {
//    officerEvent: 'projects/officerEvent',
//    virtualEdu: 'projects/virtualEdu',
//    devzAI: 'projects/devzAI',
// };
const project_label_shortcut_regex = /^([^|]+)(.*)$/;
const project_label_space_regex = /\s+/g;

const projects_preproces = (projects) => {
  return projects.map((item) => {
    const shortcut = item.title.replace(
      project_label_shortcut_regex,
      (match, p1, p2, offset, string, groups) => {
        return p1.trim();
      }
    );
    const id = shortcut.toLowerCase().replace(project_label_space_regex, '-');

    return {
      ...item,
      id,
      shortcut,
    };
  });
};

export const beProjects = projects_preproces([
  {
    title: 'Concert Ticket Tracker API',
    gallery: [
      {
        image: [TmTracker1],
        mode: 'landscape',
        description:
          'The concert ticket tracker uses Python Django framework and MongoDB to monitor seats price to a concert sold on ticketmaster.com, giving the best ticket price to concert-goers who are on budget without compromising much of their viewing experience.',
      },
      {
        image: [TmTracker2],
        mode: 'portrait',
        description: (
          <>
            The API monitors seats price by regularly fetching from
            ticketmaster.com top picks (best seats) based on the user's concert
            subscription request; upon a match, the user will be notified of the
            seats prior to make a purchase.{' '}
            <a href="https://github.com/Jackiebibili/ticket_tracker_api">
              Code on GitHub
            </a>
          </>
        ),
      },
    ],
  },
  {
    title: 'Events & Blogs Commenting System | AIAA at UT Dallas',
    gallery: [
      {
        image: [Comments2, Comments3],
        mode: 'portrait',
        description:
          "The commenting system, utilizes Spring framework and MySQL database, is a REST API handling CRUD requests on comments to events and blogs, encouraging club members to interact under the club's activity posts.",
      },
      {
        image: [Comments1],
        mode: 'landscape',
        description:
          'The comments are represented by a n-ary tree in which the root node is the event/blog and the children to the same parent are the replies, identified as a separate discussion thread. The comments (threads) are retrieved in a multi-dimensional list.',
      },
    ],
  },
]);

export const feProjects = projects_preproces([
  {
    title: 'Devz AI MVP',
    gallery: [
      {
        image: [DevzAI1],
        mode: 'portrait',
        description:
          'As the first milestone when interning at Devz AI, I incorporated the sticky horizontal bar module onto the customer landing page, which uses the Bootstrap responsive columns and rows.',
      },
      {
        image: [DevzAI2],
        mode: 'portrait',
        description:
          'Setting the stage for launching the MVP, I utilized CSS grids and flexboxes in creating a layout template highlighting the detail of product on the prelaunch page.',
      },
    ],
  },
  {
    title: 'Events & Blogs Management | AIAA at UT Dallas',
    gallery: [
      {
        image: [EventSystem1],
        mode: 'landscape',
        description:
          'The event composition page of two panes with adjustable width allows users to focus as they wish, while keeping critical input values on the screen without going back and forth between event metadata and contents.',
      },
      {
        image: [EventSystem2],
        mode: 'portrait',
        description:
          'The file collection page uses infinite scroll in loading the thumbnails as users scroll down, listing in grid the files users have uploaded to the cloud.',
      },
      {
        image: [EventSystem4],
        mode: 'landscape',
        description:
          'The event WYSIWYG editor module based on QuillJS supports live editing and saving, image upload upon drag/drop and copy from local and remote sources; features a custom tool bar and an embedded title input.',
      },
      {
        image: [EventSystem3],
        mode: 'landscape',
        description:
          'The event calendar module based on react-big-calendar gives an overview of the events categorized by types in different color (in progress) and provides a detail view in the popup, whose frame is positioned close to the page vertical center.',
      },
    ],
  },
  {
    title: 'Virtual Edu',
    gallery: [
      {
        image: [VirtualEdu2],
        mode: 'portrait',
        description:
          'The instructor schedule page facilitates the creation of  schedule within a period of time by taking constraints such as preferred daily start and end time and days of the week.',
      },
      {
        image: [VirtualEdu1],
        mode: 'portrait',
        description:
          'The calendar module enables instructors in weekly or daily view to add, clone, and remove sessions in the period of time they choose to teach.',
      },
    ],
  },
]);
