export const intro = {
  mainText1: 'Hello',
  mainText2: "I'm Jingxi (Jack),",
  mainText3: 'Seeking New Grad Role',
  mainText4: 'Full Stack Dev.',
  secondaryText1: 'CS senior at The University of Texas at Dallas',
  secondaryText2: (
    <>
      Anticipated to graduate by <u>December 2022</u>
    </>
  ),
  secondaryText3: (
    <>
      Available to start in the <u>spring of 2023</u>
    </>
  ),
};
