import React, { useState, useRef, useEffect, useCallback } from 'react';
import cn from 'classnames';
import './galleryCard.scss';

const displayMode = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
};
const SINGLE_IMAGE_SCROLL_PERSIST = 30; // in vh
const STAGE_BEFORE_TRANSITION_HEIGHT = 0.35; // 35% of viewport height
const STAGE_AFTER_TRANSITION_HEIGHT = 0.9; // 90% of viewport height
const getCardMinHeight = (num) => 100 + num * SINGLE_IMAGE_SCROLL_PERSIST; //in vh

function GalleryCard(props) {
  const { title, gallery, id } = props;
  const numImages = gallery.length;
  const containerRef = useRef(null);
  const [clientHeight, setClientHeight] = useState(0);
  const [rect, setRect] = useState(null);
  const handleScroll = (e) => {
    const elem = containerRef.current;
    if (!elem) return;
    const elemInfo = elem.getBoundingClientRect();
    const viewPortHeight =
      window.innerHeight || document.documentElement.clientHeight;
    setRect({ top: elemInfo.top, bottom: elemInfo.bottom });
    setClientHeight(viewPortHeight);
  };
  const isInFullView = useCallback(() => {
    return rect.bottom > clientHeight && rect.top < 0;
  }, [rect, clientHeight]);
  const isAfterFullView = useCallback(() => {
    return rect.bottom < clientHeight;
  }, [rect, clientHeight]);
  const isBeforeFullView = useCallback(() => {
    return rect.top > 0;
  }, [rect]);

  const opacityFunction = (x) => {
    return -1.2 * Math.pow(x, 2) + 1.2 * x + 0.7;
  };
  const getOpacityBeforeInFullView = (maxOpacity = 0.7) => {
    //stage transition
    if (
      rect.top > 0 &&
      rect.top < STAGE_BEFORE_TRANSITION_HEIGHT * clientHeight
    ) {
      return Math.min(
        maxOpacity,
        Math.max(
          1 - rect.top / (STAGE_BEFORE_TRANSITION_HEIGHT * clientHeight),
          0.05
        )
      );
    }
    return 0.05;
  };
  const getOpacityAfterInFullView = (maxOpacity = 0.7) => {
    //stage transition
    if (
      rect.bottom > 0 &&
      rect.bottom > (1 - STAGE_AFTER_TRANSITION_HEIGHT) * clientHeight
    ) {
      return Math.min(
        maxOpacity,
        Math.max(
          rect.bottom / ((1 - STAGE_AFTER_TRANSITION_HEIGHT) * clientHeight) -
            1,
          0.05
        )
      );
    }
    return 0.05;
  };
  const getOpacityTitle = (index) => {
    if (!rect || !clientHeight) {
      return 0;
    }
    if (
      index === 0 &&
      rect.top < STAGE_BEFORE_TRANSITION_HEIGHT * clientHeight
    ) {
      return Math.min(
        1,
        Math.max(
          1 - rect.top / (STAGE_BEFORE_TRANSITION_HEIGHT * clientHeight),
          0.1
        )
      );
    } else if (
      index === 0 &&
      rect.top > STAGE_BEFORE_TRANSITION_HEIGHT * clientHeight
    ) {
      return 0.1;
    }
    return 0.65;
  };
  const getOpacityDescription = (opacity) => {
    return Math.min(1, opacity * 1.05);
  };
  const getGalleryClassInfo = (opacityGradient) => {
    if (!rect || !clientHeight || !isInFullView()) {
      if (!rect || !clientHeight) {
        return { idx: 0, opacity: 0 };
      } else if (isBeforeFullView()) {
        return { idx: 0, opacity: getOpacityBeforeInFullView() };
      } else if (isAfterFullView()) {
        return { idx: numImages - 1, opacity: getOpacityAfterInFullView() };
      }
    }
    const SINGLE_IMAGE_SCROLL_PERSIST_PX =
      0.01 * SINGLE_IMAGE_SCROLL_PERSIST * clientHeight; // in px
    const idx = Math.min(
      Math.floor(Math.abs(rect.top) / SINGLE_IMAGE_SCROLL_PERSIST_PX),
      numImages - 1
    );
    const idxFloat = Math.abs(rect.top) / SINGLE_IMAGE_SCROLL_PERSIST_PX;
    const opacity = opacityGradient(idxFloat - Math.floor(idxFloat));
    return { idx, opacity };
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { idx, opacity } = getGalleryClassInfo(opacityFunction);

  return (
    <div
      id={id}
      className="gallery-card-holder"
      ref={containerRef}
      style={{ minHeight: `${getCardMinHeight(numImages)}vh` }}
    >
      <div className="gallery-card__affix">
        {gallery.map((item, index) => {
          return (
            <div className={cn({ hidden: idx !== index })} key={index}>
              <div
                className="gallery-card__title"
                style={{ opacity: getOpacityTitle(idx) }}
              >
                {title}
              </div>
              <div
                className={cn('gallery-card__flex-card', {
                  'gallery-card__flex-card--landscape':
                    item.mode === displayMode.LANDSCAPE,
                })}
              >
                <div className={cn('gallery-card__flex')}>
                  <div
                    className={cn('gallery-card__image-wrapper')}
                    style={{ opacity }}
                  >
                    {item.image.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className="gallery-card__image"
                        alt="screenshot"
                      />
                    ))}
                  </div>
                </div>
                <div
                  className={cn('gallery-card__description-row', {
                    'gallery-card__description-row--landscape':
                      item.mode === displayMode.LANDSCAPE,
                  })}
                  style={{ opacity: getOpacityDescription(opacity) }}
                >
                  <p className="gallery-card__description">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GalleryCard;
