export const me = {
  title: 'howdy',
  para: 'Welcome to my website where you will find who I am and those web apps I have built and those in progress. I am more than happy to share my excitement in developing those web apps, parts of which are now critical to the stakeholders.',
  BE: {
    start: (
      <>
        <p>
          My college education in computer science centers around
          Object-Oriented Programming (OOP), data structure and algorithm
          analysis, OS, databases, and ML/AI.
        </p>
        <p>
          During the early stage of learning OOP and data structure, some
          in-class and personal projects that have piqued my interest are
          theater ticket reservation system, Huffman coding, and balanced and
          n-ary trees.
        </p>
      </>
    ),
    skills: (
      <>
        <p>
          The main area of my expertise is Java (Spring Framework), Python,
          server-side JavaScript (Node and ExpressJS), and C/C++. I am familiar
          with RESTful API development, API documentation, SQL and NoSQL
          databases, and Docker containers.
        </p>
        <p>
          I am comfortable working in Linux environment and familiar with cloud
          infrastructure: AWS (such as S3, CodeBuild, EC2, ECS, ECR, VPC,
          Route53, and IAM) and Salesforce Cloud (such as SAQL, SOQL, and data
          recipe in Einstein Analytics). I also experimented with creating few
          CI/CD pipelines in Jenkins.
        </p>
      </>
    ),
  },
  FE: {
    start: (
      <>
        <p>
          The story of my passion in web development started in my sophomore
          year (July 2020) when I immersed myself in the magic of vanilla
          JavaScript and the manipulation of DOM.
        </p>
        <p>
          I further enriched my experience in front-end development from
          experts' voice such as Resig's <i>Secrets of the JavaScript Ninja</i>,
          Atencio's <i>Functional Programming in JavaScript</i>, and Grant's{' '}
          <i>CSS in Depth</i>.
        </p>
      </>
    ),
    skills: (
      <>
        <p>
          I create flexible, responsive, user-friendly websites and reusable,
          data-driven UI components, which many other developers incorporate in
          their large web components, and built with best practices in mind.
        </p>
        <p>
          The main area of my expertise is JavaScript/TypeScript, HTML, CSS,
          Bootstrap, unit and E2E testing, and building small and medium web
          apps using JS libraries such as React/Redux, Angular, Ember, and
          Salesforce Lightning Web Components (LWCs).
        </p>
      </>
    ),
  },
};
