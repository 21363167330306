import React, { useEffect, useRef } from 'react';
import { me } from 'constants/content/aboutMe';
import { intro } from 'constants/content/intro';
import GallerySection from 'components/common/gallery/GallerySection';
import PageDownBtn from 'components/common/button/pageDown/PageDownBtn';
import NavMenu from 'components/common/navBar/NavMenu';
import VerticalThread from 'components/common/verticalThread/VerticalThread';
import { beProjects, feProjects } from 'constants/content/projects';
import 'styles/components/_about-me.scss';

const arrowDownBtnHeight = 55;
const arrowDownBtnWidth = 46;
export const sectionAnchorId = {
  MAIN: { id: 'about-me__main-section', idx: 0 },
  INTRO: { id: 'about-me__intro-section', idx: 1 },
  JOURNEY: { id: 'about-me__journey-section', idx: 2 },
  BE_PROJECTS: { id: 'about-me__project-section-be', idx: 3 },
  FE_PROJECTS: { id: 'about-me__project-section-fe', idx: 4 },
};

const getAnchorIdList = () =>
  Object.values(sectionAnchorId).reduce((list, item) => {
    list[item.idx] = item.id;
    return list;
  }, new Array(Object.values(sectionAnchorId).length));

function AboutMe() {
  const downBtnRef = useRef(null);
  const getWindowHeight = () =>
    window.innerHeight || document.documentElement.clientHeight;
  const getWindowWidth = () =>
    window.innerWidth || document.documentElement.clientWidth;

  useEffect(() => {
    const handleWindowResize = () => {
      if (!downBtnRef.current) return;
      downBtnRef.current.style.top = `${
        getWindowHeight() - arrowDownBtnHeight
      }px`;
      downBtnRef.current.style.left = `${
        (getWindowWidth() - arrowDownBtnWidth) / 2
      }px`;
    };
    window.addEventListener('resize', handleWindowResize);
  }, []);

  return (
    <>
      <NavMenu />
      <div className="about-me-holder">
        <div className="fluid-container">
          <div className="about-me__grid-layout">
            <div
              className="about-me__main-section"
              id={sectionAnchorId.MAIN.id}
            >
              <div className="about-me__headline-secondary">
                {intro.mainText1}
              </div>
              <div className="about-me__headline-main">{intro.mainText2}</div>
              <div className="about-me__headline-main about-me__headline-main--secondary">
                {intro.mainText3}
              </div>
              <div className="about-me__headline-main about-me__headline-main--italicized">
                {intro.mainText4}
              </div>
              <div className="about-me__headline-small">
                <p>{intro.secondaryText1}</p>
                <p>{intro.secondaryText2}</p>
                <p>{intro.secondaryText3}</p>
              </div>
            </div>
            <div
              className="about-me__intro-section"
              id={sectionAnchorId.INTRO.id}
            >
              <div className="about-me__text-section">
                <div className="about-me__section-title">{me.title}</div>
                <div className="about-me__section-text">{me.para}</div>
              </div>
            </div>
            <div
              className="about-me__intro-section full-width-display"
              id={sectionAnchorId.JOURNEY.id}
            >
              <div className="about-me__text-section">
                <div className="about-me__section-title">
                  my journey & skills
                </div>
                <div className="about-me__section-container">
                  <VerticalThread content={me} />
                </div>
              </div>
            </div>
            <div
              className="about-me__project-section"
              id={sectionAnchorId.BE_PROJECTS.id}
            >
              <div className="about-me__section-title">
                my projects (back-end)
              </div>
              <GallerySection projects={beProjects} />
            </div>
            <div
              className="about-me__project-section"
              id={sectionAnchorId.FE_PROJECTS.id}
            >
              <div className="about-me__section-title">
                my portfolio (front-end)
              </div>
              <GallerySection projects={feProjects} />
            </div>
          </div>
        </div>
        <div
          className="about-me__next-btn"
          style={{
            top: `${getWindowHeight() - arrowDownBtnHeight}px`,
            left: `${(getWindowWidth() - arrowDownBtnWidth) / 2}px`,
          }}
          ref={downBtnRef}
        >
          <PageDownBtn anchors={getAnchorIdList()} includeStart />
        </div>
      </div>
    </>
  );
}

export default AboutMe;
